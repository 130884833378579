import moment from 'moment';
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { Button, Col, Dropdown, Layout,
    Menu, notification, Row, Space,
    Typography, Divider} from "antd";
import {
    CalendarOutlined,
    ExportOutlined,
    UserOutlined,
} from "@ant-design/icons";
import styles from './Layout.module.less'
import priceplan from "../../img/priceplan.png";
import useUser from "../../Providers/UserProvider";
import TimeManageModal, {BillingManageButton} from "../TimeManage/TimeManageModal";
import {DateTime} from '../Common/OtherComponents';
import Modal from "../../Base/Modals/Modal";
import AutorizationService from '../../config/autorization_backends';
import useIntegration from '../../Providers/IntegrationsProvider';
import useSystemInfo from '../../Providers/SystemProvider';
import { convert_url } from 'src/lib/tools';


function ShowMerges({children,...props}) {
    const [visible, setVisible] = useState(false);
    const {merges, is_global_permitted} = useUser();

    return <>
        <Link to={"#"} onClick={() => setVisible(true)}>
            {children}
        </Link>
        <Modal onSuccess={() => setVisible(false)}
               onCancel={() => setVisible(false)}
               visible={visible}
               btnname={'Закрыть'}
               title={'Ветви в текущей'}>
            <Layout>
                {merges?.map(x => <Row>{x}</Row>)}
            </Layout>
        </Modal>
    </>;

}

export default function Header() {
    const { user, contragent,
            time, time_machine, runningTask,
            version, branch, is_global_permitted} = useUser();
    const {system} = useSystemInfo();
    const [timeVisible, setTimeVisible] = useState(false);

    useEffect(() => {
        time_machine && notification.warning({
            message: 'Внимание! Включен режим машины времени',
            description: 'В этом режиме вы можете перемещаться во времени и тестировать различные функции, связанные с биллингом.',
            placement: 'top'
        })
    }, [])

    // useEffect(() => {
    //     new CartService().get_items().then(response => {
    //         setBucket(response.data)
    //     }).catch(err => {})
    // }, []);

    function onLogout(){
        AutorizationService.currentBackend().logout().finally(() => {
            window.location.href = '/'
        })
    }

    const profile_menu = (
        <Menu>
            <Menu.Item key="settings">
                <Link to="/settings">Настройки</Link>
            </Menu.Item>
            <Menu.Item key="profile">
                <Link to="/profile">Профиль</Link>
            </Menu.Item>
            <Menu.Item onClick={onLogout} danger icon={<ExportOutlined/>} key="logout">
                Выход
            </Menu.Item>
        </Menu>
    );

    // const menu = (
    //     <Menu>
    //         <Menu.Item key="3.1">
    //             <Link to="/dictionary">Справочники</Link>
    //         </Menu.Item>

    //         {/* <Menu.Item key="5">
    //             <Link to="/accounts">Лицевые счета</Link>
    //         </Menu.Item> */}
    //         <Menu.Item key="1">
    //             <Link to="/templates">Шаблоны документов</Link>
    //         </Menu.Item>
    //         <Menu.Item key="6">
    //             <Link to="/settings">Общие настройки</Link>
    //         </Menu.Item>
    //         <Menu.Item key="7">
    //             <Link to="/rules">Правила</Link>
    //         </Menu.Item>
    //     </Menu>
    // );

    // const contragent_menu = (
    //     <Menu>
    //         <Menu.Item key="change">
    //             <Link onClick={() => changeContragent(null)} to={"/"}>
    //                 Сменить контрагента
    //             </Link>
    //         </Menu.Item>
    //     </Menu>
    // );

    // const bucketCountItems = () => {
    //     let count = 0
    //     count += bucket.data ? bucket.data.length : 0
    //     count += bucket.packages ? bucket.packages.length : 0
    //     return count
    // }

    return (
        <Layout.Header className={time_machine ? styles.caution_header : styles.header}>
            <Row justify="space-between">
                <Col>
                    <Link to="/contragents">
                        <div style={{width: '120px', marginLeft: '2em'}}>
                            {system?.logo ? 
                                <img src={system.logo} alt='logo' style={{ backgroundSize: 'cover', width: '100%'}} /> :
                                <img src={priceplan}
                                        alt="price plan"
                                        className={styles.logo_img}
                                />
                            }
                        </div>
                    </Link>
                </Col>
                <Col/>
                <Col className={styles.col_version}>

                    {version &&
                        <Typography.Text>version: {version}</Typography.Text>}
                        <br className={styles.br}/>
                    {version && branch &&
                        <>
                            <Divider type='vertical'/>
                            branch: <ShowMerges>{branch}</ShowMerges>
                        </>}
                </Col>
                <Col>
                    <Space>
                        <Button >
                            <Typography.Text>{(contragent?.name) || "Не выбран!"}</Typography.Text>
                        </Button>
                        {(time_machine||runningTask) && is_global_permitted('application.sandbox.access') && <>
                            <Button icon={<CalendarOutlined/>}
                                    onClick={() => setTimeVisible(!timeVisible)}>
                                <DateTime dt={time || moment.now()} dateOnly/>

                            </Button>
                            <TimeManageModal visible={timeVisible || runningTask}
                                             onClose={() => {
                                                 setTimeVisible(false);
                                             }}/>
                        </>}
                        {!time_machine && user?.username=='admin' &&
                            <BillingManageButton/>}
                        <Dropdown overlay={profile_menu} trigger={["click"]} placement='bottomRight'>
                            <Button icon={<UserOutlined/>}>
                                {user && user.username}
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
            </Row>
        </Layout.Header>
    );
}
