import React, {useEffect} from "react";
import {Button, Card, Col, Menu, Row, Typography, Tag, Badge, Skeleton} from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";

import {Redirect, Route, Switch} from "react-router";
import DocumentTable from "./Tables/DocumentTable";
import ContractTable from "./Tables/ContractTable";
import OrdersTable from "./Tables/OrdersTable";
import BalanceTable from "../Accounts/Table/BalanceTable";
import {ContragentService} from "../../API/ContragentAPI";
import CardAttribute from "./Cards/ContragentAttribute";
import useContragent, {Contragent} from './ContragentContext';
import ServiceTable from "./Tables/ServiceTable";
import RevenueTable from "./Tables/RevenueTable";
import {BalanceCard} from "./Cards/BalanceCard";
import {AccountCard} from "./Cards/AccountCard";
import useUser from "../../Providers/UserProvider";
import {useLocation} from "react-use";
import {BlockingTable} from './Tables/BlockingTable';
import {PPMailTable} from './Tables/PPMailTable';

export default function ContragentDetail() {
    const {contragent, cart, setContragent, loading} = useContragent();
    const {globalUpdate} = useUser();
    const params = useParams();
    const location = useLocation();

    const MENU = [
        {code: 'common', name: "Общее", Component: CardAttribute, is_shown: () => true},
        {code: 'contracts', name: "Договоры", Component: ContractTable, is_shown: () => true},
        {code: 'orders', name: "Заказы", Component: OrdersTable, is_shown: () => true},
        {code: 'transactions', name: "Баланс", Component: BalanceTable, is_shown: () => true},
        {code: 'subscribes', name: "Услуги", Component: ServiceTable, is_shown: () => true},
        {code: 'documents', name: "Документы", Component: DocumentTable, is_shown: () => true},
        {code: 'revenue', name: "Реализации", Component: RevenueTable, is_shown: () => true},
        {code: 'blocking', name: "Блокировки", Component: BlockingTable, is_shown: () => true},
        {code: 'mail', name: "Сообщения", Component: PPMailTable, is_shown: () => true}
    ]

    useEffect(() => {
        new ContragentService(params.id).getContragentInfo().then(res => {
            setContragent(new Contragent(res.data));
        }).catch(ContragentService.create_error_handler("Не удалось запросить данные контрагента"));
    }, [params.id, globalUpdate]);

    function cartCountItems(){
        let count = 0
        count += cart.data ? cart.data.length : 0
        count += cart.packages ? cart.packages.length : 0
        return count
    }

    return (!loading ?
        <Card title={<Typography.Title level={4}>
                        {contragent?.name}
                        <Typography.Text style={{color: "#AAA", fontSize: "16px"}}>
                            {contragent?.external_id && `(external_id=${contragent.external_id})`}
                            {contragent?.blocked && <Tag style={{marginLeft: 20}} color='red'>Заблокирован</Tag>}
                        </Typography.Text>
                    </Typography.Title>}
                extra={
                    <Badge count={cart && cartCountItems()}>
                        <Link to={`/contragents/${contragent?.id}/cart`}>
                            <Button icon={<ShoppingCartOutlined />}></Button>
                        </Link>
                    </Badge>
                }
        >
            <Row gutter={[8, 8]}>
                {/* {is_permitted('application.contragent.detail.balance') &&  */}
                {/*TODO: Использовать SimplPerm*/}
                    <Col xs={12} xl={6}>
                        <AccountCard />
                    </Col>
                {/* } */}
                {/* {is_permitted('application.contragent.detail.balance') &&  */}
                    <Col xs={24} xl={12}>
                        <BalanceCard />
                    </Col>
                {/* } */}
                <Col xs={24}>
                    <Menu selectedKeys={[location.pathname]} mode='horizontal'>
                        {MENU.map(({code, name, is_shown}) => (
                            (is_shown && is_shown()) &&
                                <Menu.Item key={`/app/contragents/${contragent?.id}/${code}`}>
                                    <Link to={`/contragents/${contragent?.id}/${code}`}>{name}</Link>
                                </Menu.Item>
                        ))}
                    </Menu>
                    <Switch >
                        {MENU.map(({code, Component, is_shown}) => (
                            (is_shown && is_shown()) &&
                                <Route path={`/contragents/:id/${code}`}>
                                    <Component/>
                                </Route>
                        ))}
                        <Route exact path='/contragents/:id'>
                            <Redirect to={`/contragents/${params?.id}/common`}/>
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </Card> : <Card>
            {new Array(3).fill(0).map(_ => (
                <Skeleton></Skeleton>
            ))}
        </Card>
    );
}
