import {message, notification} from "antd";
import {ContragentService} from "app/API/ContragentAPI";
import axios from "../config/axios";
import BaseService, {error_handler} from './BaseAPI'


class SubscribeService extends BaseService {
    MODEL = 'service'

    getBillingList() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`billing`))
    }

    getPlan() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`plan`)).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    getDetalization() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`detalization`)).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    updateProlongationGroup(data) {
        // TODO: переделать на this._post
        return axios.post(this._mk_res(`prolongation`), data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    closeService() {
        // TODO: переделать на this._post
        return axios.post(this._mk_res(`close`)).then(res => {
            message.success('услуга успешно отменена');
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    createCrossgrade(data) {
        // TODO: переделать на this._post
        return axios.post(this._mk_res(`crossgrade`), data).then(res => {
            message.success('Смена осуществлена')
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    getCrossgrades() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`crossgrade`)).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    calcProlongDate(params) {
        return this._get('calc_prolong_date', {params: params})
    }

}

class SubscribeContragentService extends SubscribeService{
    MODEL = 'service'

    constructor(contragent_id) {
        super(null);
        this._ctg_id = contragent_id;
        this._ctg_service = new ContragentService(contragent_id)
    }

    list(params) {
        return super.list({params: {contragent_id: this._ctg_id, ...params}});
    }

    get model() {
        return `contragent/${this._ctg_id}/${this.MODEL}`;
    }
}

class SubscribeServiceGroup extends BaseService {
    MODEL = 'service/group'

    cancel () {
        return axios.post(this._mk_res(`cancel`)).then(res => {
            message.success('Группа подписок отменена.');
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

}

class CrossgradeService extends BaseService {
    MODEL = 'crossgrade'

    get() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res()).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    cancel() {
        // TODO: переделать на this._post
        return axios.post(this._mk_res('cancel')).then(res => {
            notification.success({message: res.data.message})
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }
}

class CounterService extends SubscribeService {
    MODEL = 'service/counter';


    addCounter(data) {
        // TODO: переделать на this._post
        return axios.post(this._mk_res(`add_counter`), data).then(res => {
            message.success('значение успешно добавлено');
            return Promise.resolve(res)
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err)
        })

    }

    lastValueCounter() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`last`)).then(res => {
            return Promise.resolve(res)
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err)
        })

    }

}


export {
    CounterService,
    SubscribeService,
    CrossgradeService,
    SubscribeServiceGroup,
    SubscribeContragentService
}
