import React, { useState, useEffect } from "react";
import {Form, Select} from 'antd';

// import Checkbox from "antd/lib/checkbox/Checkbox";
import {renderInput, DebounceSelect} from 'src/lib/dynamic-comps';

import { ContragentService } from "src/API/ContragentAPI";
import useIntegration from '../../../Providers/IntegrationsProvider'


async function fetchCAList(ca_name='') {
    // TODO: выпилить! Использовать filter
    return new ContragentService().list(
        {params: {name__icontains: ca_name, fields_client_type__icontains: 'партнер', columns: 'external_id,name'}}).then(
            res => {
                let ca_list = res.data.data.map(ca => ({label: ca.name, value: ca.external_id}))
                ca_list.push({label: 'Нет партнера', value: null})
                return ca_list
            }
        )
}

function AttrPartnerId ({attr}) {
    const [value, setValue] = useState();

    return (
        <Form.Item key={attr.codename}
                       name={attr.codename}
                       label={attr.name}
                       required={attr.is_required}
                       initialValue={attr.default}
                       >

                <DebounceSelect
                    showSearch
                    // allowClear
                    value={value}
                    placeholder="Выбор партнера"
                    fetchOptions={fetchCAList}
                    onChange={(newValue) => {
                            setValue(newValue);
                    }}
                    style={{
                        width: '100%',
                    }}
                    />
            </Form.Item>
    )
}


export default function AttributesForm({children, attributes, ...props}) {
    const {atol} = useIntegration()

    return <Form layout="vertical" {...props}>
        {children}
        {attributes.map(attr => (
            (attr.codename === 'partner_id' && atol)
            ? <AttrPartnerId attr={attr} />
            : <Form.Item key={attr.codename}
                        name={attr.codename}
                        label={attr.name}
                        required={attr.is_required}
                        initialValue={attr.default}>
                    {renderInput(attr.type, attr.enums)}
                </Form.Item>
        ))}
    </Form>
}
