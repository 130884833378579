import {error_handler} from './BaseAPI'
import {AttributableService} from "./AttributableService";
import {ContragentService} from "app/API/ContragentAPI";

export default class ContractService extends AttributableService {
    MODEL = 'contract'

    getFormData(id, only_seller=0) {
        return this._get("form-data", {
            params: {
                seller: id,
                only_seller: only_seller
            }
        });
    }

    listServices() {
        return this._get("services").then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    listForContragent(id) {
        return this._get({params: {seller: id, buyer: id}})
    }

    listByContragents(seller_id, buyer_id) {
        return this._get({params: {seller: seller_id, buyer: buyer_id}});
    }

    listActive(params) {
        return this._get('active-only', {params});
    }

    singContractWithCustomNumber(data) {
        return this._post('sing_contract_with_custom_number', data)
    }

    patchContract(id, data) {
        return this._patch(`${id}`, data)
    }

    listTypes() {
        return this._get("types");
    }

    listStatuses() {
        return this._get('statuses');
    }

    static error_handler(err, message='Ошибка. Договор') {
        return super.error_handler(err, message);
    }
}


export class ContractContragentService extends ContractService {
    MODEL='contract'
    constructor(contragent_id) {
        super(null);
        this._ctg_id = contragent_id;
        this._ctg_service = new ContragentService(contragent_id);
    }

    list(params) {
        return super.list({params: {contragent_id: this._ctg_id, ...params}});
    }

    filter(columns, attributes, filters, page, limit, search) {
        filters.contragent_id = this._ctg_id;
        return super.filter(columns, attributes, filters, page, limit, search);
    }
}
