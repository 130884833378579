import {Space, Typography} from "antd"
import React from "react"
import PropTypes from 'prop-types';

import Currency from "./Currency"

function Money({sum, type, currency, text_color, precision=2}) {
    // Конвертирует сумму ДС в презентабельный вид>

    // 810 - это старый рубль Советский. Российский рубль - это 643
    return (<Space size={2}>
        {currency !== undefined
            ? <Currency type={type} code={currency}/> : <Currency type={type} code={643}/>}
        <Typography.Text type={text_color}>{convert_money(sum, precision)}</Typography.Text>
    </Space>)
}

Money.NDS = function Nds({nds}) {

    if (isNaN(+nds)) {
        return 'Без НДС'
    }
    return (
        <Space size={2}>
            {`${+nds}%`}
        </Space>
    )
}

Money.propTypes = {
    sum: PropTypes.number,
    type: PropTypes.string,
    currency: PropTypes.string,
    text_color: PropTypes.string
}

function convert_money(sum, precision=2) {
    if (sum === undefined) {
        return "-";
    } else if (['number','string'].includes(typeof sum)) {
        return sum.toLocaleString('ru-RU', {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision
        });
    } else
        return "0";
}

export default Money;
