import React, { useEffect, useState } from "react";
import {useToggle} from "react-use";
import {Table, Space, Button, message} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

import {ProductNomenclatureService} from '../../../API/ProductAPI';

import useUser from "../../../Providers/UserProvider";

import NomenclatureModal from "../Modals/NomenclatureModal";


export default function NomenclatureTable(){
    const {is_permitted} = useUser();
    const [nomenclatureList, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [edited, setEdited] = useState(null);
    const [tgl, toggle] = useToggle(false);

    const {contragent} = useUser()

    const columns = [
        // {
        //     title: 'id',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Код в бухгалтерии',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Продукт ПрайсПлан',
            dataIndex: 'pp_product',
            key: 'pp_product',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Действие',
            key: 'operation',
            fixed: 'right',
            render: (_, record) => {
                return <Space>
                    {is_permitted('application.attribute.delete') &&
                        <Button size='small' shape='circle' icon={<DeleteOutlined />} danger ghost onClick={() => {
                            new ProductNomenclatureService(record.id).delete().then(res => {
                                message.success('Номенклатура удалёна');
                                setEdited(null);
                                toggle(!tgl)
                            }).catch(err => {
                                message.error('Возникла ошибка');
                            })
                        }} />
                    }
                    {is_permitted('application.attribute.edit') &&
                        <Button size='small' shape='circle' icon={<EditOutlined />} onClick={() => {
                            setEdited(record);
                            setVisible(true);
                            toggle(!tgl)
                        }} />
                    }
                </Space>
            },
        },
    ];

    useEffect(() => {
        if (!visible) {
            new ProductNomenclatureService().list({params: {contragent: contragent.id}}).then(res => {
                setList(res.data.data)
            })
        }
    }, [tgl])

    return <>
        {is_permitted('application.attribute.create') &&
            <Button type="primary" onClick={() => setVisible(true)} style={{marginBottom: '1em'}}>Создать</Button>
        }
        <Table size='small' dataSource={nomenclatureList} columns={columns} pagination={false} />
        <NomenclatureModal
            data={edited}
            visible={visible}
            destroyOnClose
            onCancel={() => {setVisible(false); setEdited(null)}}
            onSuccess={() => {setVisible(false); setEdited(null)}}
            contragent={contragent}
            toggle={toggle}
        />
    </>

}
