import React from 'react'
import {Button, Modal} from 'antd'
import CrossgradeServiceChangeForm, {
    ChangeProlongForm,
    CrossgradeServiceGroupChangeForm
} from '../Forms/CrossgradeServiceChangeForm';


export default function SubscribeCrossgradeModal({item, service_item, onSuccess, ...props}) {
// модальное окно по информации кроссгрейдов

    function onSuccessForm() {
        typeof onSuccess === 'function' && onSuccess();
    }

    return (
        <Modal {...props} destroyOnClose title='Выбор тарифа' footer={
            <Button htmlType='submit' form='crossgrade_change_form' type='primary'>Подтвердить</Button>
        }>
            <CrossgradeServiceChangeForm
                id='crossgrade_change_form'
                item={item}
                service_item={service_item}
                onFinish={onSuccessForm}
            />
        </Modal>
    )
}

export function SubscribeGroupModal({item, service_item, onSuccess, ...props}) {
    // модальное окно по информации кроссгрейдов

    function onSuccessForm() {
        typeof onSuccess === 'function' && onSuccess();
    }

    return (
        <Modal {...props} destroyOnClose title='Выбор тарифа' footer={
        <Button htmlType='submit' form='crossgrade_change_form' type='primary'>Подтвердить</Button>
      }>
        <CrossgradeServiceGroupChangeForm
          id='crossgrade_change_form'
          item={item}
          service_item={service_item}
          onFinish={onSuccessForm}
        />
      </Modal>
    )
}

export function SubscribeProlongGroupModal({service, onFinish, ...props}) {
  // модальное окно по информации кроссгрейдов

    const onSuccessForm = () => {
        typeof onFinish === 'function' && onFinish();
    }

    return (
      <Modal {...props} destroyOnClose title='Изменить дату продления' footer={
            <Button htmlType='submit' form='change_group_prolong' type='primary'>Подтвердить</Button>
        }>
            <ChangeProlongForm
                id='change_group_prolong'
                service={service}
                onFinish={onSuccessForm}
            />
        </Modal>
    )
}
