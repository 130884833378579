import React, { useState } from "react";
import { Modal, Input, Typography, Row } from 'antd'
import { OrderService } from "../../../API/OrderAPI";


export function PartPayOrderModal({partPayOrder, ...props}) {
  const [amount, setAmount] = useState(0)

  function partPaymentOrder() {
    const data = {
      amount: amount,
    }
    new OrderService(partPayOrder.id).pay(data).then(response => {
        props.updateList(response.data.order)
        props.setTableUpdated(!props.tableUpdated)
      })
    props.setPartPayModalOpen(false)
  }

  return (
    <Modal title="Частичная оплата" visible={props.partPayModalOpen}
        onOk={() => partPaymentOrder()}
        onCancel={() => props.setPartPayModalOpen(false)}>
        <Row justify="space-around">
          <Typography style={{marginBottom: 10}}>Сумма заказа {partPayOrder.price}</Typography>
          <Typography style={{marginBottom: 10}}>Уже оплачено {partPayOrder.paid_amount}</Typography>
        </Row>

        <Input defaultValue={partPayOrder.price} value={amount}
            onChange={(e) => setAmount(e.target.value)}/>
    </Modal>
  )
}
