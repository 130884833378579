import {message} from "antd";
import axios from "../config/axios";
import BaseService, {error_handler} from "./BaseAPI";
import UserService from "./UserAPI";
import {AttributableService} from "./AttributableService";


class ContragentService extends AttributableService {
    MODEL = "contragent";
    TRNS = "transaction";
    PPMAIL = "ppmail";

    constructor(id = null) {
        super(id);
        this.userService = new UserService();
    }

    getOffers() {
        return this._get('offers');
    }

    getMarket() {
        return this._get("market");
    }

    getPackages() {
        return this._get("packages");
    }


    getProducts() {
        return this._get("products");
    }

    listContracted() {
        return this._get("contracted");
    }

    listCloseDayBilling() {
        return this._get("billing").then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    patchUserContragent(data) {
        return this._patch("self", data);
    }

    getContragentAccount() {
        return this.accounts();
    }

    accounts(params) {
        return this._get("accounts", {params});
    }

    getContracts(params) {
        return this._get("contracts", {params: params}).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    getContragentInfo() {
        return this._get();
    }

    getDefaultAccount() {
        return this._get("default-account");
    }

    setDefaultAccount(data) {
        return this._post("default-account", data).then(res => {
            Promise.resolve(res);
            message.success(`Счёт ${data.account} сделан по умолчанию`);
        }).catch(err => {
            Promise.reject(err);
            message.error("Возникла ошибка.");
        });
    }

    getContragentUsers() {
        return this._get("users");
    }

    setCurrentContragent() {
        return this.userService.setActiveContragent(this._id);
    }

    getUsers() {
        return this._get("empty-users");
    }

    setUsers(ids) {
        return this._post("users", ids);
    }

    listOrders(params) {
        return this._get("orders", {params}).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err.response.data && err.response.data.msg) {
                message.error(err.response.data.msg);
            }
            return Promise.reject(err);
        });
    }

    calculateNextBillingDay() {
        return this._post("bill-next-day");
    }

    checkBillingTask() {
        return this._get("bill-next-day");
    }

    getLastBilledDay() {
        return this._get("last-billed-day");
    }

    filter(columns, attributes, filters, page, limit, search) {
        const _cols = [...columns, 'error_message'];

        return super.filter(_cols, attributes, filters, page, limit, search);
    }

}

// TODO: Сделано неправильно. Переделать, унаследовать от ContragentableService
class PPMAILService extends BaseService {
    MODEL = "contragent";

    constructor(contragentId, ppmailId = null) {
        super(contragentId);
        this.contragent_id = contragentId;
        this.ppmail_id = ppmailId;
    }

    forwardMails(data) {
        return this._post(`ppmail/${this.ppmail_id}/forward`, data).then(res => {
            message.success("Отправлено.");
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    list() {
        return this._get(`ppmail`).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }
}


class RuleService {

    constructor(contragentId, ruleId = null) {
        this.contragent_id = contragentId;
        this.rule_id = ruleId;
    }

    get() {
        return axios.get(`/api/contragent/${this.contragent_id}/rules/${this.rule_id}`).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    list() {
        return axios.get(`/api/contragent/${this.contragent_id}/rules`).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    create(data) {
        return axios.post(`/api/contragent/${this.contragent_id}/rules`, data).then(res => {
            message.success("правило создано");
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    update(data) {
        return axios.patch(`/api/contragent/${this.contragent_id}/rules/${this.rule_id}`, data).then(res => {
            message.success("правило обновлено");
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    create_or_update(data) {
        if (this.rule_id) {
            return this.update(data);
        }
        return this.create(data);
    }

    destroy() {
        return axios.delete(`/api/contragent/${this.contragent_id}/rules/${this.rule_id}`).then(res => {
            message.success("правило успешно удалено");
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }


}


function patchUserContragentAPI(data) {
    return new ContragentService().patchUserContragent(data);
}

function getContragentAccountAPI(id) {
    return new ContragentService(id).getContragentAccount();
}

function getContragentInfoAPI(contragentID) {
    return new ContragentService(contragentID).getContragentInfo();
}

function getDefaultAccountAPI(contragentID) {
    return new ContragentService(contragentID).getDefaultAccount();
}

export {
    ContragentService,
    RuleService,
    PPMAILService,
    patchUserContragentAPI,
    getContragentAccountAPI,
    getContragentInfoAPI,
    getDefaultAccountAPI,
};
