import {DeleteOutlined} from "@ant-design/icons";
import {
    Button,
    Card,
    Descriptions,
    Form,
    Input,
    List,
    message,
    Modal,
    Result,
    Skeleton,
    Space,
    Steps,
    Typography,
} from "antd";
import {ContragentService} from "src/API/ContragentAPI";
// import {LoadingOutlined, SolutionOutlined, UserOutlined} from '@ant-design/icons';
// import {useDebounce} from 'react-use'
import {OfferService} from "src/API/OfferAPI";
import {OrderService} from "src/API/OrderAPI";
import React, {useEffect, useState} from "react";
import {error_handler} from "src/API/BaseAPI";
import Money from "src/Components/Accounts/utils/Money";
import useContragent from "../../Contragents/ContragentContext";
import OrderForm, {PackageOrderForm} from "../Forms/OrderForm";
import {PackageOrderTable} from "../PackageTable";
import { PackageService } from "src/API/PackageAPI";

// import { DeleteOutlined } from '@ant-design/icons';


export function OrderModal({item, onCancel, ...props}) {

    const [form] = Form.useForm();
    // const {contragent} = useContragent();
    // const [update, setUpdate] = useState(false);
    const [calculatedPrice, setCalculatedPrice] = useState(false);
    const [preOrderLoading, setPreOrderLoading] = useState(false);
    const [offerDetail, setOfferDetail] = useState(null);
    const [step, setStep] = useState(-1);
    const [preOrderDetail, setPreOrderDetail] = useState(null);

    useEffect(() => {
        setStep(-1);
        if (item) {
            new OfferService(item?.id).get().then(res => {
                setOfferDetail(res.data);
                setStep(0);
            });
        }
    }, [item, form]);

    useEffect(() => {
        if (preOrderDetail) {
            new OrderService().calculate(preOrderDetail).then(res => {
                setCalculatedPrice(res.data);
            });
        }
    }, [preOrderDetail]);

    function onCloseModal() {
        onReset();
        typeof onCancel === "function" && onCancel();
    }

    function onReset() {
        setStep(0);
        setPreOrderDetail(null);
        // setCreateOrder(null);
    }

    function onNextStep(data) {
        setStep(1);
        data["offer"] = offerDetail.id;
        let send_data = {...data, offer: item["id"]};
        setPreOrderDetail(data);
        new OrderService().calculate(send_data).then(res => {
            setCalculatedPrice(res.data);
        });
    }

    function onCreateOrder() {
        setPreOrderLoading(true);
        new OrderService().create(preOrderDetail).then((res) => {
            // setCreateOrder(res.data);
            setStep(2);
        }).catch(error_handler).finally(() => {
            setPreOrderLoading(false);
        });
    }

    function StepButton({step, ...props}) {
        switch (step) {
            case 0:
                return <Button htmlType="submit" form="order_form" {...props}>Перейти к подтверждению</Button>;
            case 1:
                return <Space>
                    <Button onClick={() => {
                        setStep(0);
                        form.setFieldsValue(preOrderDetail);
                    }}>Назад</Button>
                    <Button loading={preOrderLoading} type="primary" onClick={onCreateOrder} {...props}>Оформить
                        заказ</Button>
                </Space>;
            default:
                return <></>;
        }
    }


    return <Modal destroyOnClose={true} width={"80%"} title="Создание заказа" {...props} onCancel={onCloseModal}
                  footer={
                      <StepButton step={step}/>
                  }>
        <Form.Item>
            <Card size="small" title="Этапы">
                <Steps current={step}>
                    <Steps.Step title="Заполнение данных" key={0}/>
                    <Steps.Step title="Подтверждение заказа" key={1}/>
                    <Steps.Step title="Создание заказа" key={2}/>
                </Steps>
            </Card>
        </Form.Item>
        {step === -1 && new Array(1).fill(1).map(el => (
            <Skeleton></Skeleton>
        ))}
        {step === 0 &&
            <OrderForm
                layout="vertical"
                form={form}
                id="order_form"
                type={offerDetail?.type}
                onFinish={onNextStep}
            />
        }
        {step === 1 && <>
            <Form.Item>
                <Card size="small" title="Промокод">
                    <Form id="check_promocode">
                        <Form.Item name="promocode" validateTrigger={[]} rules={[{
                            required: false, validator: (_, v) => {
                                if (!v) {
                                    return Promise.resolve();
                                }
                                return new OrderService().calculate({
                                    ...preOrderDetail,
                                    promocode: v,
                                }).then(res => {
                                    setCalculatedPrice(res.data);
                                    setPreOrderDetail({...preOrderDetail, promocode: v});
                                    message.success("Промокод применен");
                                    return Promise.resolve();
                                }).catch((err) => {
                                    return Promise.reject(err.response?.data?.msg);
                                });
                            },
                        }]}>
                            <Input allowClear suffix={<Button htmlType="submit" type="primary" size="small"
                                                              form="check_promocode">Применить</Button>}/>
                        </Form.Item>
                    </Form>
                </Card>
            </Form.Item>
            <Form.Item>
                <Card size="small" title="Информация о заказе">
                    <Descriptions size="small" bordered column={2}>
                        <Descriptions.Item label="Продукт" span={2}>
                            {item && item.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Тип услуги" span={2}>{item?.type_name}</Descriptions.Item>
                        {calculatedPrice?.offer?.taxes === "NO_VAT" ?
                            <Descriptions.Item label="НДС" span={2}>Без НДС</Descriptions.Item> :
                            <Descriptions.Item label="НДС" span={2}>{calculatedPrice?.offer?.taxes}%</Descriptions.Item>
                        }
                        <Descriptions.Item label="Цена" span={2}>
                            <Money sum={calculatedPrice.price}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Количество" span={2}>
                            {calculatedPrice.count}
                        </Descriptions.Item>
                        {calculatedPrice.discount && <Descriptions.Item label="Скидка" span={2}>
                            <Money sum={calculatedPrice.discount}/>
                        </Descriptions.Item>}
                        <Descriptions.Item label="Итого"><Money sum={calculatedPrice.cost}/></Descriptions.Item>
                    </Descriptions>
                </Card>
            </Form.Item>
            {calculatedPrice?.discounts?.length !== 0 &&
                <Form.Item>
                    <Card size="small" title="Скидки">
                        <List size="small"
                              bordered
                              dataSource={calculatedPrice?.discounts}
                              renderItem={(el) => (
                                  <List.Item>
                                      <Typography.Text>{el?.name}</Typography.Text>
                                  </List.Item>
                              )}
                        />
                    </Card>
                </Form.Item>
            }
            {calculatedPrice?.promocode &&
                <Card size="small" title="Промокоды">
                    <List size="small"
                          bordered
                          dataSource={[calculatedPrice?.promocode]}
                          renderItem={() => (
                              <List.Item extra={
                                  <DeleteOutlined onClick={() => {
                                      setPreOrderDetail({
                                          contract: preOrderDetail.contract,
                                          offer: preOrderDetail.offer,
                                          count: preOrderDetail.count,
                                          account: preOrderDetail.account,
                                      });
                                  }}/>
                              }>
                                  <Typography.Text>{calculatedPrice?.promocode.name}</Typography.Text>
                              </List.Item>
                          )}
                    />
                </Card>
            }
        </>}
        {step === 2 &&
            <Result
                status="success"
                title="Заказ успешно создан!"
                subTitle={<Typography.Text>
                    <Button type="link" onClick={onCancel}>Перейти в заказы</Button>
                </Typography.Text>}
                extra={[]}
            />
        }
    </Modal>;
}

export function PackageOrderModal({item, ...props}) {

    const [form] = Form.useForm();
    const [calculatedPrice, setCalculatedPrice] = useState(false);
    const [prices, setPrices] = useState([]);
    const [step, setStep] = useState(-1);
    const [preOrderDetail, setPreOrderDetail] = useState(null);
    const {contragent} = useContragent();

    useEffect(() => {
        setStep(-1);
        if (item) {
            // setLoading(true);
            new PackageService(item.id).getPrices().then(res => {
                setPrices(res.data.data);
                setStep(0);
            })
        }
    }, [item]);

    useEffect(() => {
        if (item) {
            new ContragentService(contragent.id).getContracts(
                {seller: item.contragent, type_contract: 3},
            ).then(res => {
                // setContracts(res.data.data);
            });
        }
    }, [contragent]);


    function createOrder(data) {
        data["offers"] = prices;
        data["package_id"] = item?.id;

        new OrderService().createNewPackageOrder(data).then((res) => {
            if (typeof props.onSuccess === "function") {
                props.onSuccess(res);
            }
            typeof props.onCancel === "function" && props.onCancel();
        });

    }

    function StepButton({step, ...props}) {
        switch (step) {
            case 0:
                return <Button htmlType="submit" form="package_order_form" {...props}>Перейти к подтверждению</Button>;
            case 1:
                return <Space>
                    <Button onClick={() => {setStep(0)}}>Назад</Button>
                    <Button type="primary" onClick={onCreateOrder} {...props}>Оформить заказ</Button>
                </Space>;
            default:
                return <></>;
        }
    }

    function onNextStep(data) {
        setStep(1);
        let extract_data = {...data, package: item.id};
        setPreOrderDetail(extract_data);
        new OrderService().calculatePackage(extract_data).then(res => {
            setCalculatedPrice(res.data);
        });
    }

    function onCreateOrder() {
        new OrderService().createNewPackageOrder(preOrderDetail).then((res) => {
            if (typeof props.onSuccess === "function") {
                props.onSuccess(res);
            }
            typeof props.onCancel === "function" && props.onCancel();
        });
    }

    return <Modal 
            destroyOnClose={true} 
            width={'80%'} 
            title={<Typography.Text>Заказ на пакет 
                    <Typography.Text keyboard>{item?.name}</Typography.Text>
                    </Typography.Text>
            } 
            {...props} 
            footer={
            <StepButton step={step} />
    }>
        <Form.Item>
            <Card size="small" title="Этапы">
                <Steps current={step}>
                    <Steps.Step title="Заполнение данных" key={0}/>
                    <Steps.Step title="Подтверждение заказа" key={1}/>
                    <Steps.Step title="Создание заказа" key={2}/>
                </Steps>
            </Card>
        </Form.Item>
        {step === -1 && <Skeleton></Skeleton>}
        {step === 0 && <Card size='small' title='Данные заказа'>
            <PackageOrderForm
                layout="vertical"
                form={form} id="package_order_form"
                onFinish={onNextStep}
            />
        </Card>}
        {step === 1 && <>
            <Form.Item>
                <Card size='small' title='Информация по заказу'>
                    <Form.Item>
                        <Descriptions size='small' bordered column={1}>
                            <Descriptions.Item label='Наименование'>{calculatedPrice?.package?.name}</Descriptions.Item>
                            <Descriptions.Item label='Описание'>{calculatedPrice?.package?.description}</Descriptions.Item>
                            <Descriptions.Item label='Счет оплаты'>{calculatedPrice?.account?.number}</Descriptions.Item>
                            <Descriptions.Item label='№ Контракта'>{calculatedPrice?.contract?.number}</Descriptions.Item>
                        </Descriptions>
                    </Form.Item>
                    <Form.Item>
                        <PackageOrderTable
                            dataSource={calculatedPrice?.order_items}
                        />
                    </Form.Item>
                </Card>
            </Form.Item>
            <Form.Item>
                <Card size="small" title="Промокод">
                    <Form id="check_promocode">
                        <Form.Item name="promocode" validateTrigger={[]} rules={[{
                            required: false, validator: (_, v) => {
                                if (!v) {
                                    return Promise.resolve();
                                }
                                return new OrderService().calculatePackage({
                                    ...preOrderDetail,
                                    promocode: v,
                                }).then(res => {
                                    setCalculatedPrice(res.data);
                                    setPreOrderDetail({...preOrderDetail, promocode: v});
                                    message.success("Промокод применен");
                                    return Promise.resolve();
                                }).catch((err) => {
                                    return Promise.reject(err.response?.data?.msg);
                                });
                            },
                        }]}>
                            <Input allowClear suffix={<Button htmlType="submit" type="primary" size="small"
                                                            form="check_promocode">Применить</Button>}/>
                        </Form.Item>
                    </Form>
                </Card>
            </Form.Item>
            {calculatedPrice?.discounts?.length !== 0 &&
                <Form.Item>
                    <Card size="small" title="Скидки">
                        <List size="small"
                              bordered
                              dataSource={calculatedPrice?.discounts}
                              renderItem={(el) => (
                                  <List.Item>
                                      <Typography.Text>{el?.name}</Typography.Text>
                                  </List.Item>
                              )}
                        />
                    </Card>
                </Form.Item>
            }
            {calculatedPrice?.promocodes &&
                <Card size="small" title="Промокоды">
                    <List size="small"
                          bordered
                          dataSource={calculatedPrice?.promocodes}
                          renderItem={(field) => (
                              <List.Item extra={
                                  <DeleteOutlined onClick={() => {
                                    let extract = {
                                        contract: preOrderDetail.contract,
                                        offer: preOrderDetail.offer,
                                        count: preOrderDetail.count,
                                        account: preOrderDetail.account,
                                        package: preOrderDetail.package
                                    }
                                        new OrderService().calculatePackage(extract).then(res => {
                                            setCalculatedPrice(res.data);
                                            setPreOrderDetail(extract);
                                            return Promise.resolve();
                                        }).catch((err) => {
                                            return Promise.reject(err.response?.data?.msg);
                                        });
                                      
                                  }}/>
                              }>
                                  <Typography.Text>{field.name}</Typography.Text>
                              </List.Item>
                          )}
                    />
                </Card>
            }
        </>}
    </Modal>;
}
