import {DownloadOutlined} from "@ant-design/icons";
import {Button, Select, Space} from "antd";
import {clearForModel} from "app/Base/Tables/FlexibleTable/SaveLoad";
import React, {useState} from "react";
import {SearchDataTable} from "../SearchTable";
import {ViewSetModal} from "./FlexibleModal";
import {useColumns, useFlexible} from "./FlexibleTable";


export function FilterPanel({title}) {
    const {
        current, viewsets, setCurrent, save,
        downloadAsCSV, downloadAsXLSX, model, toggleTableUpdate,
        clearViewSets
    } = useFlexible();
    const [modalVisible, setModalVisible] = useState(false);

    function onSelect(val) {
        setCurrent(val);
    }

    function onSuccess() {
        setModalVisible(false);
    }

    return <>
        <Space>
            <Select onChange={(e) => onSelect(e)}
                    style={{minWidth: 200}}
                    value={current?.name}>
                {viewsets.map(f => {
                                  return <Select.Option key={f.name} value={f.name}>
                                      {f.name} {f.default && "По умолчанию"}
                                  </Select.Option>;
                              },
                )}
            </Select>
            <Button onClick={() => setModalVisible(true)}
                    title={"Редактировать настройки: колонки, фильтры, сортировка"}>
                ...
            </Button>
            <Button icon={<DownloadOutlined/>} onClick={downloadAsCSV}>csv</Button>
            <Button icon={<DownloadOutlined/>} onClick={downloadAsXLSX}>xlsx</Button>
            {/*<Button icon={<DeleteOutlined/>} onClick={onDelete} danger*/}
            {/*        title={"Очистить все фильтры (Использовать в случае если фильтры не работают)"}/>*/}
            {title && typeof title == "function" && title()}
        </Space>
        <ViewSetModal visible={modalVisible}
                      onSuccess={onSuccess}
                      onCancel={() => setModalVisible(false)}/>
    </>;
}


export function FlexibleData({model, title, expandable, extra, onUpdate, ...props}) {
    const {columns} = useColumns();
    const {
        toggleTableUpdate,
    } = model;

    return <>
        <SearchDataTable model={model}
                         columns={columns}
                         title={() => <FilterPanel/>}
                         additional_title={title}
                         toggle={() => {
                             toggleTableUpdate();
                             onUpdate?.();
                         }}
                         extra={extra}
                         expandable={expandable}
                         {...props}
        />
    </>;
}
