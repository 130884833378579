import {useEffect, useState} from 'react';
import {Button, Col, Row, Table, Tag} from 'antd'

import {ReloadOutlined} from "@ant-design/icons";
import {OrderItemService} from '../../../API/OrderAPI';
import {SubscribeService} from '../../../API/ServiceAPI';
import {useEffectOnce} from 'react-use';
import Money from '../../Accounts/utils/Money';
import useContragent from '../ContragentContext';
import {ContragentService} from '../../../API/ContragentAPI';
import {DateTime} from '../../Common/OtherComponents';


export default function BillingOrderTable({order_item, ...props}) {

    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});


    useEffectOnce(() => {
        new OrderItemService(order_item).history_list_counter().then(res => {
            setBillingList(res.data.data);
            setGeneralInfo({
                count: res.data?.count || 0,
                amount: res.data?.amount,
                taxes: res.data?.taxes,
                without_taxes: res.data?.without_taxes
            })
        })

    }, [order_item])

    const columns = [
        {title: '№', dataIndex: 'id', width: 50},
        {
            title: 'значение',
            dataIndex: 'count'
        },
        {
            title: 'сумма без НДС', dataIndex: 'without_tax', render: (_, record) => (
                <Money sum={record.amount - record.taxes}/>
            )
        },
        {
            title: 'сумма', dataIndex: 'amount', render: sum => (
                <Money sum={parseFloat(sum)}/>
            )
        },
        {
            title: 'НДС', dataIndex: 'taxes', render: sum => (
                <Money sum={parseFloat(sum)}/>
            )
        },
        {
            title: 'дата внесения', dataIndex: '_created', width: 150, render: (dt) => {
                return <DateTime dt={dt}/>
            }
        },
        {
            title: 'утчен в расчетах?',
            dataIndex: 'invoice_doc_id',
            key: 'invoice_doc_id',
            render: (value) => {
                return value === null ? 'Нет' : 'Да'
            }
        },
        {
            title: 'комментарий',
            dataIndex: 'description',
            key: 'description',
        },
    ]

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey='id'
            size='small'
            summary={(_) => {

                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.count}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.without_taxes}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.amount}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.taxes}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )
            }}
            {...props}
        />
    )
}

export function SubscribePlanTable({service_item, ...props}) {
    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});


    useEffectOnce(() => {
        if (service_item && service_item?.id) {
            new SubscribeService(service_item?.id).getPlan().then(res => {
                setBillingList(res.data.data);
                setGeneralInfo({
                    total_amount: res.data?.total_amount,
                    total_billing_amount: res.data?.total_billing_amount,
                    // total_tax: res.data?.total_tax,
                })
            })
        }

    }, [service_item])

    const columns = [
        {title: '№', dataIndex: 'id', width: 50},
        {title: 'Дата создания', dataIndex: 'created', width: 100, render: (dt) => {
            return <DateTime dt={dt} dateOnly />
        }},
        {
            title: 'план', dataIndex: 'amount', render: sum => (
                <Money sum={sum} precision={4}/>
            )
        },
        {
            title: 'Факт', dataIndex: 'billing_amount', render: sum => (
                sum && <Money sum={sum} precision={4}/>
            )
        },
        {title: 'Значение', dataIndex: 'count', width: 100},
        {
            title: '№ реализации', dataIndex: 'revenue_number', render: (rnum, record) => {
                switch (record.revenue_status) {
                    case "template":
                        return <Tag>{rnum}</Tag>;
                    case "active":
                        return <Tag color={'green'}>{rnum}</Tag>;
                    case "incorrect":
                        return <Tag color={'yellow'}>{rnum}</Tag>;
                    case "problem":
                        return <Tag color={'red'}>{rnum}</Tag>;
                    default:
                        return rnum;
                }
            }
            // title: '№ реализации', dataIndex: 'revenue_number', render: rId => (
            //     rId && `#${rId}`
            // )
        },
        // {
        //     title: 'НДС', dataIndex: 'tax', render: sum => (
        //         <Money sum={parseFloat(sum)}/>
        //     )
        // },
        {title: 'Фактическая дата', dataIndex: 'date', width: 100, render: (dt) => {
            return <DateTime dt={dt} dateOnly />
        }},
        {
            title: 'комментарий',
            dataIndex: 'description',
            key: 'description',
        },
    ]

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey='id'
            size='small'
            summary={(_) => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell>-</Table.Summary.Cell>
                            <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_amount)}/></Table.Summary.Cell>
                            <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_billing_amount)}/></Table.Summary.Cell>
                            {/*<Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_tax)}/></Table.Summary.Cell>*/}
                        </Table.Summary.Row>
                    </Table.Summary>
                )
            }}
            {...props}
        />
    )
}

export function SubscribeDetalizationTable({service_item, ...props}) {
    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});


    useEffectOnce(() => {
        if (service_item && service_item?.id) {
            new SubscribeService(service_item?.id).getDetalization().then(res => {
                setBillingList(res.data.data);
                setGeneralInfo({
                    total_amount: res.data?.total_amount,
                    // total_tax: res.data?.total_tax,
                })
            })
        }

    }, [service_item])

    const columns = [
        {title: '№', dataIndex: 'id', width: 50},
        {
            title: 'сумма', dataIndex: 'amount', render: sum => (
                <Money sum={parseFloat(sum)}/>
            )
        },

        {title: 'Значение', dataIndex: 'count', width: 100},
        {title: 'Дата создания', dataIndex: 'created', width: 100, render: (dt) => {
            return <DateTime dt={dt} dateOnly />
        }},
        {title: 'Фактическая дата', dataIndex: 'date', width: 100, render: (dt) => {
            return <DateTime dt={dt} dateOnly />
        }},
        {
            title: 'комментарий',
            dataIndex: 'description',
            key: 'description',
        },
    ]

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey='id'
            size='small'
            summary={(_) => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_amount)}/></Table.Summary.Cell>
                            {/*<Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_tax)}/></Table.Summary.Cell>*/}
                        </Table.Summary.Row>
                    </Table.Summary>
                )
            }}
            {...props}
        />
    )
}

// export function BillingServiceTable({service_item, ...props}) {
//     const [billingList, setBillingList] = useState([]);
//     const [generalinfo, setGeneralInfo] = useState({});


//     useEffectOnce(() => {
//         if (service_item && service_item?.id) {
//             new SubscribeService(service_item?.id).getBillingList().then(res => {
//                 setBillingList(res.data.data);
//                 setGeneralInfo({
//                     count: res.data?.count || 0,
//                     amount: res.data?.amount,
//                     taxes: res.data?.taxes,
//                     without_taxes: res.data?.without_taxes
//                 })
//             })
//         }

//     }, [service_item])

//     const columns = [
//         {title: '№', dataIndex: 'id', width: 50},
//         {
//             title: 'значение',
//             dataIndex: 'count'
//         },
//         {
//             title: 'сумма без НДС', dataIndex: 'without_tax', render: (_, record) => (
//                 <Money sum={record.amount - record.taxes}/>
//             )
//         },
//         {
//             title: 'сумма', dataIndex: 'amount', render: sum => (
//                 <Money sum={parseFloat(sum)}/>
//             )
//         },
//         {
//             title: 'НДС', dataIndex: 'taxes', render: sum => (
//                 <Money sum={parseFloat(sum)}/>
//             )
//         },
//         {
//             title: 'комментарий',
//             dataIndex: 'description',
//             key: 'description',
//         },
//     ]

//     return (
//         <Table
//             bordered
//             columns={columns}
//             dataSource={billingList}
//             rowKey='id'
//             size='small'
//             summary={(_) => {
//                 return (
//                     <Table.Summary fixed>
//                         <Table.Summary.Row>
//                             <Table.Summary.Cell>Итого</Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.count)}/></Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.without_taxes)}/></Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.amount)}/></Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.taxes)}/></Table.Summary.Cell>
//                         </Table.Summary.Row>
//                     </Table.Summary>
//                 )
//             }}
//             {...props}
//         />
//     )
// }

export function BillingCloseDayTable() {
    const {contragent} = useContragent();
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);


    const columns = [
        {title: '№', dataIndex: 'id', key: 'id'},
        {
            title: 'дата биллинга', dataIndex: 'date_billing', render: (dt) => {
                return <DateTime dt={dt}/>
            }
        },
        {title: 'описание', dataIndex: 'description'},
        {
            title: 'статус', dataIndex: 'status', render: (_, record) => {

                switch (record.status) {
                    case 1:
                        return 'в процессе'
                    case 2:
                        return 'успешно'
                    case 3:
                        return 'провален'
                }
            }
        },
    ]

    useEffect(() => {
        setLoading(true);
        new ContragentService(contragent?.id).listCloseDayBilling().then(res => {
            setList(res.data.data);
        }).finally(_ => {
            setLoading(false);
        })

    }, [contragent, update])

    return (
        <Table
            title={() => (
                <Row justify='space-between'>
                    <Col>

                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setUpdate(p => !p);
                            setLoading(true)
                        }}/>
                    </Col>
                </Row>
            )}
            loading={loading}
            size='small'
            columns={columns}
            dataSource={list}
        />
    )
}
