import React, {useEffect, useState} from 'react'
import {Button, DatePicker, Descriptions, Form, InputNumber, message, Radio, Typography} from 'antd'
import {SelectOfferModal, SelectPackageModal} from '../Modals/SelectOfferModal'
import {OfferService} from '../../../API/OfferAPI';import {PackageService} from '../../../API/PackageAPI';
import Money from '../../Accounts/utils/Money';
import {SubscribeService, SubscribeServiceGroup} from '../../../API/ServiceAPI';
import useUser from "../../../Providers/UserProvider";

import moment from 'moment';


export default function CrossgradeServiceChangeForm({item, service_item, ...props}) {
    const [visibleSelectOfferModal, setVisibleSelectOfferModal] = useState(false);
    const [detailSubscribe, setDetailSubscribe] = useState(service_item?.id);
    const [offerDetail, setOfferDetail] = useState(null);
    const {user, time, time_machine} = useUser();
    const [form] = Form.useForm();

    const onFinishForm = (data) => {
        data['offer'] = offerDetail?.id;
        if (data?.date_start) {
            data['date_start'] = moment(data.date_start);
        }
        if (data?.prolong_date) {
            data['prolong_date'] = moment(data.prolong_date).format('YYYY-MM-DD');
        }

        if (data) {
            new SubscribeService(service_item?.id).createCrossgrade(data).then(res => {
                typeof props.onFinish === 'function' && props.onFinish(data);
            })
        }
    }

    useEffect(() => {
        if (service_item) {
            new SubscribeService(service_item?.id).getPlan()
            new SubscribeService(service_item?.id).getDetalization()
            new SubscribeService(service_item?.id).get().then(res => {
                setDetailSubscribe(res.data);
            })
        }
    }, [service_item])

    useEffect(() => {
        if (detailSubscribe?.offer) {
            new OfferService(detailSubscribe?.offer).get().then(res => {
                setOfferDetail(res.data);
            })
        }
    }, [detailSubscribe])

    function handleProlong() {
        new SubscribeService().calcProlongDate({
            current_subscribe_id: item.id,
            change_offer_id: offerDetail.id,
        }).then(res => {
            form.setFieldsValue({
                prolong_date: moment(res.data.new_settling_date)
            })
        })
    }

    useEffect(() => {
        if (offerDetail?.id) {
            handleProlong()
        }
    }, [offerDetail])

    return (
        <Form layout='vertical' {...props} form={form} onFinish={onFinishForm}>
            {offerDetail &&
            <Form.Item>
                <Descriptions column={1} title='Тариф' bordered size='small' extra={<Typography>
                    <Button type='link' onClick={() => setVisibleSelectOfferModal(true)}>Сменить тариф</Button>
                </Typography>}>
                    <Descriptions.Item label='наименование предложения'>{offerDetail?.name}</Descriptions.Item>
                    <Descriptions.Item label='цена'><Money sum={offerDetail?.price}
                                                           currency={offerDetail?.currency}/></Descriptions.Item>
                    <Descriptions.Item label='НДС'><Money.NDS nds={offerDetail?.taxes}/></Descriptions.Item>
                    <Descriptions.Item
                        label='Единица измерения'>{offerDetail?.product?.measure_name || 'не задан'}</Descriptions.Item>
                </Descriptions>
            </Form.Item>
            }

            {/* <Form.Item label='начало периода' name='date_start'>
        <DatePicker />
      </Form.Item> */}
            <Form.Item label='Дата продления' name='prolong_date' initialValue={moment(service_item?.settling_day)}>
                <DatePicker defaultPickerValue={time} showToday={false} dateRender={current => {
                    let css_prop = {}
                    if (current == time) {
                        css_prop.border = '1px solid blue';
                    }
                    return <div className="ant-picker-cell-inner" style={css_prop}>
                        {current.date()}
                    </div>
                }} style={{width: '100%'}} disabledDate={(current) => {
                    // Can not select days before today and today
                    return time_machine ? current < time : current && current < moment().endOf('day');
                }}/>
            </Form.Item>
            <Form.Item
                name='count'
                initialValue={service_item?.count}
                label={`количественный показатель ${offerDetail?.product?.measure_name || ''}`}
                help={
                    <Typography.Text type='secondary'>
                        {`Минимальное возможное ${offerDetail?.product?.measure_name || 'количество'} = ${offerDetail?.min_orders}`}<br></br>
                        {`Максимально возможное ${offerDetail?.product?.measure_name || 'количество'} = ${offerDetail?.max_orders ? offerDetail?.max_orders : 'неограниченно'}`}
                    </Typography.Text>
                }
            >
                <InputNumber style={{width: '100%'}} min={offerDetail?.min_orders} max={offerDetail?.max_orders}
                             prefix={offerDetail?.product?.measure_name || 'количество'}/>
            </Form.Item>
            <SelectOfferModal
                visible={visibleSelectOfferModal}
                onCancel={() => setVisibleSelectOfferModal(false)}
                onSelectOffer={(record) => {
                    setOfferDetail(record);
                    setVisibleSelectOfferModal(false);
                }}
            />
            <Form.Item label='Пересчитать цену и создать заказ' name='is_crossgrade' initialValue={false}>
                <Radio.Group>
                    <Radio.Button disabled value={true}>Да</Radio.Button>
                    <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
}

export function CrossgradeServiceGroupChangeForm({item, service_item, ...props}) {
    const [visibleSelectOfferModal, setVisibleSelectOfferModal] = useState(false);
    const [detailSubscribe, setDetailSubscribe] = useState(service_item?.id);
    const [packageDetail, setPackageDetail] = useState(null);
    const [form] = Form.useForm();
    const {time, time_machine} = useUser();

    const onFinishForm = (data) => {
        data['package'] = packageDetail?.id;
        if (data?.prolong_date) {
            data['prolong_date'] = moment(data.prolong_date).format('YYYY-MM-DD');
        }

        if (data) {
            new SubscribeServiceGroup(service_item?.group).create(data).then(res => {
                typeof props.onFinish === 'function' && props.onFinish(data);
            })
        }
    }

    useEffect(() => {
        if (service_item) {
            new PackageService(service_item?.package_id).get().then(res => {
                setPackageDetail(res.data);
            })
        }
    }, [service_item])

    useEffect(() => {
        if (detailSubscribe?.offer) {
            new PackageService(detailSubscribe?.package_id).get().then(res => {
                setPackageDetail(res.data);
            })
        }
    }, [detailSubscribe])


    function handleProlong() {
        new PackageService().calcProlongDate({
            current_package_id:detailSubscribe,
            change_package_id: packageDetail.id,
            current_subscribe_id: service_item.children[0].id,
            current_offer_id: service_item.children[0].offer, })
            .then(res => {
                form.setFieldsValue({
                    prolong_date: moment(res.data.new_settling_date)})
            })
    }

    useEffect(() => {
        if (packageDetail?.id) {
            handleProlong()
        }
    }, [packageDetail])

    return (
        <Form layout='vertical' {...props} form={form} onFinish={onFinishForm}>
            {packageDetail &&
                <Form.Item>
                    <Descriptions column={1} title='Тариф' bordered size='small' extra={<Typography>
                        <Button type='link' onClick={() => setVisibleSelectOfferModal(true)}>Сменить тариф</Button>
                    </Typography>}>
                        <Descriptions.Item label='наименование предложения'>{packageDetail?.name}</Descriptions.Item>
                    </Descriptions>
                </Form.Item>
            }
            <Form.Item label='Дата продления'
                       name='prolong_date'
                       initialValue={moment(service_item?.settling_day)}>
                <DatePicker defaultPickerValue={time}
                            showToday={false}
                            dateRender={current => {
                                let css_prop = {}
                                if (current == time) {
                                    css_prop.border = '1px solid blue';
                                }
                                return <div className="ant-picker-cell-inner" style={css_prop}>
                                    {current.date()}
                                </div>
                            }} style={{width: '100%'}}

                            disabledDate={(current) => {
                                // Can not select days before today and today
                                return time_machine ? current < time : current && current < moment().endOf('day');
                            }}
                />
            </Form.Item>
            <Form.Item label='Пересчитать цену и создать заказ' name='is_recalculate' initialValue={false}>
                <Radio.Group>
                    <Radio.Button disabled value={true}>Да</Radio.Button>
                    <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <SelectPackageModal
                visible={visibleSelectOfferModal}
                onCancel={() => setVisibleSelectOfferModal(false)}
                onSelect={(record) => {
                    setPackageDetail(record);
                    setVisibleSelectOfferModal(false);
                }}
            />
        </Form>
    )
}

export function ChangeProlongForm({service, ...props}) {
    const {time, time_machine} = useUser();
    useEffect(() => {
    }, [service])

    function onFinishForm(data) {
        data['group'] = service?.group;
        data['prolong_date'] = moment(data.prolong_date).format('YYYY-MM-DD');
        new SubscribeService().updateProlongationGroup(data).then(res => {
            message.success('Дата продления обновлена');
            typeof props.onFinish === 'function' && props.onFinish(data);
        });
    }

    return (
        <Form layout='vertical' {...props} onFinish={onFinishForm}>
            <Form.Item label='Дата продления' name='prolong_date' rules={[
                {required: true, message: 'Обязательное поле к заполнению'}
            ]}>
                <DatePicker format={'YYYY-MM-DD'} defaultValue={moment(new Date(service?.settling_day))}disabledDate={(current) => {
                    return time_machine ? current < time :current && current < moment().endOf('day');
                }}/>
            </Form.Item>
        </Form>
    )
}
