import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Table, Typography, Tag, Descriptions, Form, Input, List, message} from "antd";

import {OrderService} from "app/API/OrderAPI";
import {OrderPayStatusTag, orderStatusTag, OrderTypeTag} from "../../Common/Tags";
import {AccountShortDetail, ContractShortDetail, DateTime} from "../../Common/OtherComponents";
import {OrderItemTable} from '../Tables/OrdersTable';
import Money from "../../Accounts/utils/Money";


export function OrderTransactionsTable({transactions, loading}) {
    const columns = [
        {title: '№', dataIndex: 'id', key: 'id', align: 'center'},
        {title: 'Дата', dataIndex: '_created', key: '_created', align: 'center', render: (dt) => {
            return <DateTime dt={dt} />
        }},
        {
            title: 'Тип', dataIndex: 'type_name', key: 'type_name', align: 'center', render: (type_name, tr) => {
                if (type_name === 'Оплата') return <Typography.Text type='success'>{type_name}</Typography.Text>
                if (type_name === 'Отмена') return <Typography.Text type='danger'>{type_name}</Typography.Text>
                return <Typography.Text>{type_name}</Typography.Text>
            }
        },
        {title: 'НДС', dataIndex: 'tax', key: 'tax', align: 'right', render: (value) => {
            return <Money sum={value} />
        }},
        {
            title: 'Сумма', dataIndex: 'amount', key: 'amount', align: 'right', render: (amount, tr) => {
                if (tr.type_name === 'Оплата') return <Money sum={amount} text_color={'success'}/>
                if (tr.type_name === 'Отмена') return <Money sum={amount} text_color={'danger'}/>
                return <Money sum={amount} />
            }
        },
    ]
    return (
      <Table size='small' columns={columns} dataSource={transactions} pagination={false} align={'center'} loading={loading}/>
    )
}


export function OrderDetail({orderDetail, setOrderDetail, contragent}) {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        if (orderDetail) {
            const ord_srv = new OrderService(orderDetail.id);

            setLoading(!!orderDetail);
            Promise.all([
                ord_srv.get(),
                ord_srv.listTransactions()
            ]).then(([order, tranzz]) => {
                setOrderDetail(order.data);
                setTransactions(tranzz.data.data);
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [update])

    return <Card size='small' title={`Заказ № ${orderDetail.id}`}
                 extra={setOrderDetail && <Button type='primary' size='small' onClick={() => setOrderDetail(false)}>Назад</Button>}
                 loading={loading}
    >
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card size='small' title='Информация'>
                    <Descriptions bordered size='small'>
                        <Descriptions.Item label='Наименование' span={3}>{orderDetail.name}</Descriptions.Item>
                        <Descriptions.Item label='идентификатор' span={3}>{orderDetail.id}</Descriptions.Item>
                        <Descriptions.Item label='UID' span={3}>{orderDetail.uuid}</Descriptions.Item>
                        <Descriptions.Item label='Номер' span={3}>{orderDetail.number}</Descriptions.Item>
                        <Descriptions.Item label='Клиент'>{orderDetail.buyer && orderDetail.buyer.name}</Descriptions.Item>
                        <Descriptions.Item label='Статус'>
                            {orderDetail.active_blockings
                                            ? <Tag color='red'>Заблокировано</Tag>
                                            : orderStatusTag(orderDetail.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label='Тип' >

                            < OrderTypeTag el={orderDetail.type} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Создан'><DateTime dt={orderDetail.date} dateOnly /></Descriptions.Item>
                        <Descriptions.Item label='Стоимость'><Money sum={orderDetail.price}/></Descriptions.Item>
                        <Descriptions.Item label='Оплачено'><Money sum={orderDetail.paid_amount}/></Descriptions.Item>
                        <Descriptions.Item label='Статус оплаты'>{OrderPayStatusTag(orderDetail.status_pay)}</Descriptions.Item>
                        <Descriptions.Item label='Контракт'>
                            {orderDetail.contract &&
                                <ContractShortDetail contract={orderDetail.contract} br={true}/>}
                        </Descriptions.Item>
                        <Descriptions.Item label='Счет'>
                            {orderDetail.seller_account && <AccountShortDetail account={orderDetail.seller_account}/>}
                        </Descriptions.Item>
                        <Descriptions.Item label='Дата завершения'>
                            <DateTime dt={orderDetail.realize_date} ifnull={'Не завершен'}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>
            <Col xs={24}>
                <Card size='small' title='Список позиций'>
                    <OrderItemTable order_id={orderDetail.id}
                                    order={orderDetail}
                                    contragent_id={contragent.id}
                                    setOrderDetail={setOrderDetail}
                                    loading={loading}
                                    viewMode={'edit'}
                    />
                </Card>
            </Col>
            <Col xs={24}>
                <Card size='small' title='Список транзакций'>
                    <OrderTransactionsTable transactions={transactions} loading={loading} />
                </Card>
            </Col>
            {!!orderDetail?.attributes?.length &&
                <Col xs={24}>
                    <Card size='small' title='Атрибуты заказа'>
                        <Descriptions size='small' column={1} bordered>
                            {orderDetail?.attributes?.map(el => (
                                <Descriptions.Item label={el.name}>{JSON.stringify(el.value)}</Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Card>
                </Col>
            }
            {orderDetail.meta_order &&
                <Col xs={24}>
                    <Card size='small' title='Дополнительная информация'>
                        {
                            <p>
                                тип {orderDetail.meta_order.type}.
                                Дата активации заморозки {orderDetail.meta_order.activate_date}.
                                Дата окончания заморозки {orderDetail.meta_order.cancel_date}.
                                Продлено на {orderDetail.meta_order.prolong_days} дней
                            </p>
                        }
                    </Card>
                </Col>

            }
            <Col xs={24}>
                <Card size='small' title='Промокод' layout='inline'>
                    <Row gutter={4}>
                        <Col flex='1'>
                            <Form id='promocode_form' onFinish={
                                (d) => new OrderService(orderDetail?.id).setPromocode(d).then(res => {
                                    setUpdate(p => !p);
                                })
                            }>
                                <Form.Item name='promocode'>
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col>
                            <Button htmlType='submit' type='primary' form='promocode_form'>Найти и применить все скидки</Button>
                        </Col>
                    </Row>

                    {!!orderDetail.promocodes?.length &&
                        <Card size='small' title='Промокоды'>
                            <List size='small' bordered dataSource={orderDetail.promocodes}
                                renderItem={(item) => (
                                    <List.Item>{item.name}</List.Item>
                                )}
                            >
                            </List>
                        </Card>
                    }

                </Card>
            </Col>
            {!!orderDetail.discounts?.length &&
                <Col xs={24}>
                    <Card size='small' title='Модификаторы скидок'
                          extra={<Button size='small' danger onClick={() => {
                            new OrderService(orderDetail?.id).clearDiscounts().then(res => {
                                message.success('Скидки и промокоды удалены');
                                setUpdate(p => !p);
                            })
                          }}>Сбросить</Button>}>
                        <Descriptions size='small' column={1} bordered>
                            {orderDetail.discounts.map(el => (
                                <Descriptions.Item label={el.name}>
                                    {el?.type_pay === 'money' ? <Money sum={el.value} /> : `${el.value}%`}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Card>
                </Col>
            }
        </Row>
    </Card>
}
