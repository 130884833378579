import {DeleteOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

import {
    Button,
    Card,
    Checkbox,
    Col,
    Descriptions,
    Form,
    Input,
    List,
    notification,
    PageHeader,
    Row,
    Select,
    Skeleton,
    Space,
    Table,
    Typography,
} from "antd";
import React, {useEffect, useReducer, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useLocalStorage} from "react-use";
import {ContragentService} from "src/API/ContragentAPI";

import {CartService} from "src/API/OrderAPI";
import ContractSelect from "src/Base/Inputs/ContractSelect";
import useUser from "src/Providers/UserProvider";
import Money from "../Accounts/utils/Money";

import {AccountShortDetail, ContractShortDetail, ItemTax} from "../Common/OtherComponents";
import {getItemFromListById, replaceItemInListById} from "../Common/utils";
import useContragent, {Contragent} from "../Contragents/ContragentContext";


// TODO: ну я не знаю, это надо рефакторить, а желательно переделать все.

export function EditItemCount({item, value, onChangeItemCount}) {
    const [newCount, setNewCount] = useState(value);

    useEffect(() => {
        setNewCount(value);
    }, [value]);

    function onBlurInput() {
        onChangeItemCount(item.id, parseFloat(newCount));
    }

    return <Input defaultValue={value} value={newCount} onChange={(e) => setNewCount(e.target.value)}
                  onBlur={onBlurInput}/>;
}

function PreOrderItemsTable(props) {
    const {items, onDeleteOrderItem, onChangeItemCount, mode} = props;
    const {contragent} = useContragent();


    const columns = [
        {
            title: "Продукт",
            dataIndex: "product_name",
            key: "product_name",
            width: 250,
            align: "left",
        },
        {
            title: "Услуга",
            dataIndex: "product_type_name",
            key: "product_type_name",
            width: 150,
            align: "left",
        },
        {
            title: "Количество",
            dataIndex: "count",
            key: "count",
            width: 100,
            align: "center",
            render: function (value, item) {
                // if (mode === 'edit')
                //     return <EditItemCount
                //         item={item}
                //         value={value}
                //        onChangeItemCount={onChangeItemCount}
                //     />
                // else return <OrderItemCountPlusMeasure item={item}/>
                return <Space>
                    <MinusCircleOutlined onClick={() => {
                        let val = value - 1;
                        if (val >= 1) onChangeItemCount(item.id, val);
                    }}/>
                    <Typography.Text>{value}</Typography.Text>
                    <PlusCircleOutlined onClick={() => {
                        onChangeItemCount(item.id, value + 1);
                    }}/>
                </Space>;
            },
        },
        {
            title: "Цена за единицу",
            dataIndex: "price",
            key: "price",
            align: "right",
            width: 100,
            render: (price, item) =>
                <Money sum={item.product_type_name === "счётчик" ? 0 : price} type={"symbol"} currency={810}/>,
        },
        {
            title: "НДС",
            dataIndex: "tax",
            key: "tax",
            align: "right",
            width: 100,
            render: function (tax, item) {
                return <ItemTax calculated_tax={tax} currency={item?.currency} percent_tax={item.percent_tax}/>;
            },
        },
        {
            title: "сумма",
            dataIndex: "total_price",
            key: "total_price",
            align: "right",
            width: 100,
            render: (total_price, item) =>
                <Money sum={item.product_type_name === "счётчик" ? 0 : total_price} type={"symbol"} currency={810}/>,
        },
        {
            title: "скидка",
            dataIndex: "discount",
            key: "discount",
            align: "right",
            width: 100,
            render: (total_price, item) =>
                <Money sum={item.product_type_name === "счётчик" ? 0 : total_price} type={"symbol"} currency={810}/>,
        },
        {
            title: "",
            dataIndex: "delete",
            key: "delete",
            align: "center",
            width: 100,
            render: function (name, item) {
                return mode === "edit" && <Button size="small" shape="circle" icon={<DeleteOutlined/>}
                                                  onClick={() => onDeleteOrderItem(item.id)} danger/>;
            },
        },
    ];
    return <Table columns={columns} rowKey={"id"} dataSource={items} pagination={false} size={"small"}
                  style={{marginTop: 20}} showHeader={true}/>;
}


function PreOrderPackage({packages, increasePackage, decreasePackage, deletePackage}) {
    // TODO: Полная жесть.

    const packageColumns = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Количество",
            dataIndex: "count_package",
            key: "count_package",
        },
        {
            title: "Сумма",
            dataIndex: "total",
            key: "total",
            render: (total) => <Money sum={total} type={"symbol"} currency={810}/>,
        },
        {
            title: "",
            dataIndex: "increase_decrease",
            key: "increase_decrease",
            render: function (name, item) {
                return <Row justify="space-between">
                    <Button size="small" shape="circle" icon={<PlusCircleOutlined/>}
                            onClick={() => increasePackage(item.id)}/>
                    <Button size="small" shape="circle" icon={<MinusCircleOutlined/>}
                            onClick={() => decreasePackage(item.id)}/>
                </Row>;
            },
        },
        {
            title: "",
            dataIndex: "delete",
            key: "delete",
            render: function (name, item) {
                return <Button size="small" shape="circle" icon={<DeleteOutlined/>}
                               onClick={() => deletePackage(item.id)} danger/>;
            },
        },
    ];

    const packageItemColumns = [
        {
            title: "Продавец",
            dataIndex: "seller",
            key: "seller",
        },
        {
            title: "Продукт",
            dataIndex: "product_name",
            key: "product_name",
        },
        {
            title: "Услуга",
            dataIndex: "product_type_name",
            key: "product_type_name",
        },
        {
            title: "Количество",
            dataIndex: "count",
            key: "count",
        },
        {
            title: "Цена за единицу",
            dataIndex: "price",
            key: "price",
            render: (price, item) => <Money sum={item.product_type_name === "счётчик" ? 0 : price} type={"symbol"}
                                            currency={810}/>,
        },
        {
            title: "НДС",
            dataIndex: "tax",
            key: "tax",
            render: function (tax, item) {
                return <ItemTax calculated_tax={tax} currency={item?.currency} percent_tax={item.percent_tax}/>;
            },
        },
        {
            title: "Сумма",
            dataIndex: "total_price",
            key: "total_price",
            render: (price, item) => <Money sum={item.product_type_name === "счётчик" ? 0 : price} type={"symbol"}
                                            currency={810}/>,
        },
        {
            title: "Скидка",
            dataIndex: "discount",
            key: "discount",
            render: (value) => <Money sum={value} type={"symbol"} currency={810}/>
        },
    ];

    return <div style={{marginTop: 20}}>
        <h3>Пакеты</h3>
        <Table
            style={{marginTop: 20}}
            columns={packageColumns}
            dataSource={packages}
            pagination={false}
            expandable={{
                expandedRowRender: (record) => (
                    <Table dataSource={record.items}
                           columns={packageItemColumns}
                           pagination={false}/>),
            }}
        />
    </div>;
}

function PreOrder({update, cartStorage, setCartStorage, ...props}) {
    // TODO: Полная жесть. Почему не используются формы?

    const {contragent} = useContragent();
    const params = useParams();
    const {mode, preOrder, accounts, dispatch, setBucket} = props;
    const [allowedContracts, setAllowedContracts] = useState([]);
    // const [cartStorage, setCartStorage, remove] = useLocalStorage(`cart_${params.id}`, {checked: [], contracts: []});

    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        new ContragentService(contragent.id).getContracts().then(response => {
            setAllowedContracts(response.data.data);
        });
    }, []);

    // const [, cancel] = useDebounce(
    //     () => {
    //       setDebouncedValue(val);
    //     },
    //     2000,
    //     [val]
    //   );

    useEffect(() => {
        if (preOrder.packages === null) {
            setTotalItems(preOrder.items.length);
        }
        if (preOrder.items && preOrder.packages) {
            setTotalItems(preOrder.items.length + preOrder.packages.length);
        }
    }, [preOrder]);

    function onSelectAccount(value) {
        dispatch({
            type: "set_pre_order_account",
            payload: {
                pre_order_key: preOrder.key,
                account: accounts.filter(acc => acc.id === value)[0],
            },
        });
        // if (value !== 0)
        //     new ContractService().listActive({
        //         buyer: contragent?.id,
        //         seller: preOrder.seller_id,
        //         seller_account: preOrder.seller_account_id,
        //         buyer_account: preOrder.account.id,
        //         type_contract: 3
        //     }).then(response => {
        //         setAllowedContracts(response.data.data)
        //     })
    }

    function onSelectContract(value, option) {
        let contracts = cartStorage.contracts;
        let exist = !contracts.filter(el => el.key === preOrder.key).length;
        if (exist) {
            contracts.push({key: preOrder.key, contract: allowedContracts.filter(contract => contract.id === value)[0]});
            setCartStorage({...cartStorage, contracts});
        }
        dispatch({
            type: "set_pre_order_contract",
            payload: {
                pre_order_key: preOrder.key,
                contract: allowedContracts.filter(contract => contract.id === value)[0],
            },
        });
    }

    function onDeleteOrderItem(item_id) {
        new CartService(contragent?.id).delete_item({order_item_id: item_id, contragent: contragent?.id})
                                       .then(res => {
                                           setBucket(res.data);
                                           dispatch({
                                               type: "delete_pre_order_item",
                                               payload: {pre_order_key: preOrder.key, order_item_id: item_id},
                                           });

                                           if (res.data.data.length === 0)
                                               dispatch({
                                                   type: "delete_pre_order",
                                                   payload: {pre_order_key: preOrder.key},
                                               });
                                           else
                                               dispatch({
                                                   type: "set_pre_order_total_items",
                                                   payload: {pre_order_key: preOrder.key},
                                               });
                                       });
    }

    function onChangeItemCount(item_id, count) {
        new CartService(contragent?.id).change_item_count({
            order_item_id: item_id, contragent: contragent?.id,
            new_count: count,
        }).then(res => {
            new CartService(contragent.id).transform_items_to_pre_orders().then(res => {
                setBucket(res.data);
                let changed_item = getItemFromListById(res.data.pre_orders[0].items, item_id);
                dispatch({
                    type: "change_pre_order_item",
                    payload: {pre_order_key: preOrder.key, order_item_id: item_id, newItem: changed_item},
                });
                dispatch({type: "set_pre_order_total_items", payload: {pre_order_key: preOrder.key}});

            });
        });
    }

    function onCheckChange(e) {
        let checked = cartStorage.checked;
        if (e.target.checked) {
            checked.push(preOrder.key);
        } else {
            checked = checked.filter(id => id !== preOrder.key);
        }
        setCartStorage({...cartStorage, checked});
        dispatch({
            type: "set_pre_order_checked",
            payload: {pre_order_key: preOrder.key, checked: e.target.checked},
        });
    }

    function deletePackage(packageId) {
        new CartService(contragent?.id).delete_package({package: packageId, contragent: contragent?.id})
                                       .then(res => {
                                           setBucket(res.data);
                                           dispatch({
                                               type: "delete_pre_order_package",
                                               payload: {
                                                   pre_order_key: preOrder.key,
                                                   package_id: packageId,
                                               },
                                           });
                                       });
    }

    function increasePackage(packageId) {
        new CartService(contragent?.id).add_package({package: packageId, contragent: contragent?.id})
                                       .then(res => {
                                           setBucket(res.data);
                                           let newPackage = res.data.packages.filter(pkg => pkg.id === packageId)[0];

                                           dispatch({
                                               type: "change_pre_order_package",
                                               payload: {
                                                   pre_order_key: preOrder.key,
                                                   package_id: packageId,
                                                   newPackage: newPackage,
                                               },
                                           });
                                       });
    }

    function decreasePackage(packageId) {
        new CartService(contragent?.id).decrease_package({package: packageId, contragent: contragent?.id})
                                       .then(res => {
                                           setBucket(res.data);
                                           let newPackage = res.data.packages.filter(pkg => pkg.id === packageId)[0];

                                           dispatch({
                                               type: "change_pre_order_package",
                                               payload: {
                                                   pre_order_key: preOrder.key,
                                                   package_id: packageId,
                                                   newPackage: newPackage,
                                               },
                                           });
                                       });
    }

    return <>
        <Card bordered
              size="small"
              title={preOrder.order_name}
              extra={mode === "edit" &&
                  <Space>
                      <Typography>Выбрать</Typography>
                      <Checkbox
                          value={preOrder.checked}
                          checked={preOrder.checked}
                          onChange={onCheckChange}
                      />
                  </Space>
              }
        >
            {mode !== "edit" &&
                <Form.Item>
                    <Card title="Промокод" size="small">
                        <Form id="check_promocode" layout="vertical">
                            <Form.Item name="promocode" validateTrigger={[]} label="Промокод" rules={[{
                                required: false, validator: (_, v) => {
                                    return new CartService(contragent?.id).checkPromocode({
                                        promocode: v,
                                    }).then(response => {
                                        setAllowedContracts(response.data.data);
                                        typeof update === "function" && update();
                                        notification.success({message: `Промокод ${v} применен`});
                                        return Promise.resolve();
                                    }).catch((err) => {
                                        return Promise.reject(err.response?.data?.msg);
                                    });
                                },
                            }]}>
                                <Input suffix={<Button htmlType="submit" type="primary" size="small"
                                                       form="check_promocode">Применить</Button>}/>
                            </Form.Item>
                        </Form>
                    </Card>
                </Form.Item>
            }
            <Form.Item>
                <Card title="Данные предзаказа" size="small">
                    <Row gutter={[20, 20]}>
                        {mode === "edit" && <>
                            <Col xs={12}>
                                <Form layout="vertical">
                                    <Form.Item label="Счёт">
                                        <Select
                                            defaultValue={preOrder?.account && preOrder.account.id}
                                            onSelect={onSelectAccount}>
                                            {accounts.map(account =>
                                                <Select.Option value={account.id}>
                                                    <AccountShortDetail account={account}/>
                                                </Select.Option>,
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col xs={12}>
                                <Form layout="vertical">
                                    <Form.Item label="Договор">
                                        <ContractSelect onSelect={onSelectContract} contragent={contragent}/>
                                    </Form.Item>
                                </Form>
                            </Col>
                            {/* <Col xs={12}>
                                <Form layout='vertical'>
                                    <Form.Item label='Реферальный код'>
                                        <Input onChange={(e) => fillRefCode(e.target.value)} />
                                    </Form.Item>
                                </Form>

                            </Col> */}
                            {/* <Col xs={12}>
                                <Form id='check_promocode' layout='vertical'>
                                    <Form.Item name='promocode' validateTrigger={[]} label='Промокод' rules={[{
                                        required: false, validator: (_, v) => {
                                            return new CartService(contragent?.id).checkPromocode({
                                                promocode: v
                                            }).then(response => {
                                                setAllowedContracts(response.data.data)
                                                notification.success({message: `Промокод ${v} применен`})
                                                return Promise.resolve()
                                            }).catch((err) => {
                                                return Promise.reject(err.response?.data?.msg)
                                            })
                                        }
                                    }]}>
                                            <Input suffix={<Button htmlType='submit' size='small' form='check_promocode'>Применить</Button>} />
                                    </Form.Item>
                                </Form>
                            </Col> */}
                        </>}
                        {mode !== "edit" && <>
                            <Col xs={12}>
                                <Typography.Text strong italic>Договор</Typography.Text>
                                <ContractShortDetail contract={preOrder.contract} br={true}/>
                            </Col>
                            <Col xs={6}>
                                {preOrder?.promocode && <>
                                    <Typography.Text strong italic>Промокод</Typography.Text>
                                    <Typography>{preOrder.promocode}</Typography>
                                </>}
                            </Col>
                        </>}
                    </Row>
                </Card>
            </Form.Item>
            <Form.Item>
                <Card title="Информация о предзаказе" size="small">
                    <Descriptions bordered size="small" column={1}>
                        <Descriptions.Item span={2} label="Продавец">{preOrder.seller_name}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Количество позиций">{totalItems}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Сумма заказа">
                            <Money sum={preOrder.total_items ? preOrder.total_items : 0.00} type={"symbol"}
                                   currency={810}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Form.Item>
            <Form.Item>
                <Card title="позиции" size="small">
                    {preOrder.items && preOrder.items.length > 0 &&
                        <PreOrderItemsTable
                            items={preOrder.items}
                            onDeleteOrderItem={onDeleteOrderItem}
                            onChangeItemCount={onChangeItemCount}
                            mode={mode}/>
                    }
                    {preOrder.packages && preOrder.packages.length > 0 &&
                        <PreOrderPackage
                            size="small"
                            packages={preOrder.packages}
                            increasePackage={increasePackage}
                            decreasePackage={decreasePackage}
                            deletePackage={deletePackage}
                        />}
                </Card>
            </Form.Item>
            <Form.Item>
                <Card title="скидки" size="small">
                    <List
                        size="small"
                        bordered
                        dataSource={preOrder?.discounts}
                        renderItem={(field) => (
                            <List.Item>
                                {field.name}
                            </List.Item>
                        )}
                    />
                </Card>
            </Form.Item>
            <Card title="промокоды"
                  size="small"
                  extra={<Button danger size="small" onClick={() => {
                      new CartService(contragent.id).removePromocodes().then(res => {
                          update();
                      });
                  }}>
                      Удалить промокоды
                  </Button>}>
                <List
                    size="small"
                    bordered
                    dataSource={preOrder?.promocodes}
                    renderItem={(field) => (
                        <List.Item>
                            {field.name}
                        </List.Item>
                    )}
                />
            </Card>
        </Card>
    </>;
}

function ButtonsBlock({update, ...props}) {
    const {
        mode, setViewMode, disabledNext, onClickCreateOrders,
        setOrdersToCheck, dispatch, setSumToCheck,
    } = props;

    function clickReset() {
        setOrdersToCheck([]);
        dispatch({type: "reset_accounts_and_contracts", payload: null});
        dispatch({type: "uncheck_pre_orders", payload: null});
        setViewMode("edit");
        setSumToCheck(0);
        typeof update === "function" && update();
    }

    switch (mode) {
        case "edit":
            return <Button type="primary" size={"small"} onClick={() => {
                setViewMode("check");
                update();
            }}
                           disabled={disabledNext}
            >Далее</Button>;
        case "check":
            return <Space>
                <Button size={"small"} onClick={clickReset}>Назад</Button>
                <Button size={"small"} type="primary" onClick={() => onClickCreateOrders()}>Создать заказы</Button>
            </Space>;
        case "created":
            return <Row justify="space-between">
                {/* <Button size={'large'} onClick={clickReset}>В корзину</Button> */}
                {/* <Typography.Title level={5} style={{textAlign: 'center'}}>
            Заказы успешно созданы!
          </Typography.Title> */}
            </Row>;
        default:
    }
}

function init(initData) {
    return initData;
}

function replaceItemInList(list, item) {
    const index = list.indexOf(item);
    list = [
        ...list.slice(0, index),
        item,
        ...list.slice(index + 1),
    ];

    return list;
}

function sumOrderItemsPrice(preOrder) {
    return preOrder.items.reduce((acc, item) => (
            acc + (item.product_type_name !== "счётчик") ?
                (parseFloat(item.price) * parseFloat(item.count)) : 0), 0)
        + (preOrder.packages || []).reduce((acc, pkg) => (acc + pkg.total), 0);
}

function deletePreOrder(preOrders, payload) {
    const {pre_order_key} = payload;

    return preOrders.filter(preOrder => preOrder.key !== pre_order_key);
}

function deletePreOrders(preOrders, payload) {
    const {keys} = payload;
    return preOrders.filter(preOrder => !keys.includes(preOrder.key));
}

function deletePreOrderItem(preOrders, payload) {
    const {pre_order_key, order_item_id} = payload;

    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.items = targetPreOrder.items.filter(item => item.id !== order_item_id);

    return replaceItemInList(preOrders, targetPreOrder);
}

function changePreOrderItem(preOrders, payload) {
    const {pre_order_key, order_item_id, newItem} = payload;

    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.items = replaceItemInListById(targetPreOrder.items, order_item_id, newItem);

    targetPreOrder.total_items = sumOrderItemsPrice(targetPreOrder);
    return replaceItemInList(preOrders, targetPreOrder);
}

function setPreOrderAccount(preOrders, payload) {
    const {pre_order_key, account} = payload;
    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.account = account;
    return replaceItemInList(preOrders, targetPreOrder);
}

function setPreOrderContract(preOrders, payload) {
    const {pre_order_key, contract} = payload;
    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.contract = contract;
    return replaceItemInList(preOrders, targetPreOrder);
}

function setRefCode(preOrders, payload) {
    const {pre_order_key, ref_code} = payload;
    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.ref_code = ref_code;
    return replaceItemInList(preOrders, targetPreOrder);
}

function setRefPromocode(preOrders, payload) {
    const {pre_order_key, promocode} = payload;
    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.promocode = promocode;
    return replaceItemInList(preOrders, targetPreOrder);
}

function setPreOrderChecked(preOrders, payload) {
    const {pre_order_key, checked} = payload;
    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.checked = checked;
    return replaceItemInList(preOrders, targetPreOrder);
}

function setPreOrderTotalItems(preOrders, payload) {
    const {pre_order_key} = payload;
    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];
    targetPreOrder.total_items = sumOrderItemsPrice(targetPreOrder);
    return replaceItemInList(preOrders, targetPreOrder);
}

function resetAccountsAndContracts(preOrders, payload) {
    for (const order of preOrders) {
        order.account = null;
        order.contract = null;
    }
    return preOrders;
}

function uncheckPreOrders(preOrders, payload) {
    for (const order of preOrders) {
        order.checked = false;
    }
    return preOrders;
}

function deletePreOrderPackage(preOrders, payload) {
    const {pre_order_key, package_id} = payload;

    let targetPreOrder = preOrders.filter(preOrder => preOrder.key = pre_order_key)[0];
    targetPreOrder.packages = targetPreOrder.packages.filter(pkg => pkg.id !== package_id);

    return replaceItemInList(preOrders, targetPreOrder);
}

function changePreOrderPackage(preOrders, payload) {
    const {pre_order_key, package_id, newPackage} = payload;

    let targetPreOrder = preOrders.filter(preOrder => preOrder.key === pre_order_key)[0];

    targetPreOrder.packages = replaceItemInListById(targetPreOrder.packages, package_id, newPackage);

    targetPreOrder.total_items = sumOrderItemsPrice(targetPreOrder);
    return replaceItemInList(preOrders, targetPreOrder);
}

function reducer(state, action) {
    switch (action.type) {
        case "set_pre_order_contract":
            return setPreOrderContract(state, action.payload);
        case "set_fill_ref_code":
            return setRefCode(state, action.payload);
        case "set_fill_promocode":
            return setRefPromocode(state, action.payload);
        case "set_pre_order_account":
            return setPreOrderAccount(state, action.payload);
        case "delete_pre_order":
            return deletePreOrder(state, action.payload);

        case "delete_pre_orders":
            return deletePreOrders(state, action.payload);

        case "delete_pre_order_item":
            return deletePreOrderItem(state, action.payload);

        case "set_pre_order_checked":
            return setPreOrderChecked(state, action.payload);

        case "reset_accounts_and_contracts":
            return resetAccountsAndContracts(state, action.payload);

        case "change_pre_order_item":
            return changePreOrderItem(state, action.payload);

        case "set_pre_order_total_items":
            return setPreOrderTotalItems(state, action.payload);

        case "uncheck_pre_orders":
            return uncheckPreOrders(state, action.payload);

        case "change_pre_order_package":
            return changePreOrderPackage(state, action.payload);

        case "delete_pre_order_package":
            return deletePreOrderPackage(state, action.payload);

        case "init":
            return init(action.payload);
        default:
            throw new Error();
    }
}

export default function CartDetail() {
    const {contragent, setContragent} = useContragent();
    const params = useParams();
    const {bucket, setBucket} = useUser();

    const [update, setUpdate] = useState(false);

    const [statePreOrders, dispatch] = useReducer(reducer, [], init);

    const [accounts, setAccounts] = useState([]);
    const [viewMode, setViewMode] = useState("edit");
    const [disabledNext, setDisabledNext] = useState(true);
    const [sumToCheck, setSumToCheck] = useState(0);
    const [ordersToCheck, setOrdersToCheck] = useState([]);
    const [cartStorage, setCartStorage, remove] = useLocalStorage(`cart_${params.id}`, {checked: [], contracts: []});
    const [loading, setLoading] = useState(true);
    const history = useHistory();


    useEffect(() => {
        setLoading(true);
        new ContragentService(params.id).getContragentInfo().then(res => {
            let ctg = new Contragent(res.data);
            setContragent(ctg);
            new CartService(ctg?.id).transform_items_to_pre_orders({contragent: ctg?.id}).then(res => {
                let pre_orders = res.data.pre_orders.map(el => {
                    el.checked = cartStorage.checked.includes(el.key);
                    let contracts = cartStorage.contracts.filter(c => c.key === el.key);
                    let contract = null;
                    if (contracts.length) {
                        contract = contracts[0].contract;
                        el.contract = contract;
                    }
                    return el;
                });
                dispatch({type: "init", payload: pre_orders});
                setAccounts(res.data.buyer_accounts);
                setLoading(false);
            });
        }).catch(ContragentService.create_error_handler("Не удалось запросить данные контрагента"));
    }, [params.id, update]);

    useEffect(() => {
        if (contragent?.id) {
            new CartService(contragent?.id).transform_items_to_pre_orders({contragent: contragent?.id}).then(res => {
                let pre_orders = res.data.pre_orders.map(el => {
                    el.checked = cartStorage.checked.includes(el.key);
                    let contracts = cartStorage.contracts.filter(c => c.key === el.key);
                    let contract = null;
                    if (contracts.length) {
                        contract = contracts[0].contract;
                        el.contract = contract;
                    }
                    return el;
                });
                dispatch({type: "init", payload: pre_orders});
                setAccounts(res.data.buyer_accounts);
            });
        }
    }, [bucket]);

    useEffect(() => {
        const checkedOrders = statePreOrders.filter(preOrder => cartStorage.checked.includes(preOrder.key) || preOrder.checked);
        setOrdersToCheck(checkedOrders);
        setSumToCheck(checkedOrders.reduce((p, co) => co.total_items + p, 0));

    }, [statePreOrders]);

    useEffect(() => {
        if (ordersToCheck.length > 0) {
            const ordersToCheckWithoutContracts = ordersToCheck.filter(order => order.contract === 0 || order.contract === null || order.contract === undefined);
            if (ordersToCheckWithoutContracts.length > 0) {
                setDisabledNext(true);
            } else {
                setDisabledNext(false);
            }
        } else {
            setDisabledNext(true);
        }

    }, [ordersToCheck]);

    function onClickCreateOrders() {
        const preOrderKeys = ordersToCheck.map(order => order.key);
        new CartService(contragent?.id).pre_order_to_orders({
            orders: ordersToCheck,
            contragent: contragent?.id,
        }).then(response => {
            setBucket(response.data);
            dispatch({type: "delete_pre_orders", payload: {keys: preOrderKeys}});
            setOrdersToCheck([]);
            setViewMode("created");
            remove();
            history.push(`/contragents/${contragent?.id}/orders`);
        });
    }

    function onClickClearBucket() {
        new CartService(contragent?.id).delete_all_items({contragent: contragent?.id}).then(res => {
            setBucket(res.data);
            dispatch({type: "init", payload: []});
            setOrdersToCheck([]);
            setSumToCheck(0);
            remove();
        });

    }

    function onChangeCart(d) {
        setBucket(d);
    }

    return (<PageHeader
            onBack={() => window.history.back()}
            title={viewMode === "edit" ? `Корзина ${contragent?.name}` : `Проверяем данные ${contragent?.name}`}
            style={{background: "white"}}>
            <Form.Item>
                <Card title="Общая информация" size="small" extra={
                    <Space>
                        <Button size="small" onClick={() => onClickClearBucket()} type="danger">
                            Очистить корзину
                        </Button>
                        <ButtonsBlock
                            mode={viewMode}
                            setViewMode={setViewMode}
                            onClickCreateOrders={onClickCreateOrders}
                            disabledNext={disabledNext}
                            setOrdersToCheck={setOrdersToCheck}
                            dispatch={dispatch}
                            setSumToCheck={setSumToCheck}
                            update={() => setUpdate(p => !p)}
                        />
                    </Space>
                }>
                    <Descriptions bordered column={1} size="small">
                        <Descriptions.Item span={2} label="Заказов">{ordersToCheck.length}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Итого">
                            <Money sum={sumToCheck} type={"symbol"} currency={810}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Form.Item>
            {loading ? <Skeleton/> : (viewMode === "edit" && statePreOrders.length > 0)
                && statePreOrders.map(preOrder =>
                    <PreOrder
                        mode={viewMode}
                        preOrder={preOrder}
                        accounts={accounts}
                        contragent={contragent}
                        dispatch={dispatch}
                        setBucket={(d) => onChangeCart(d)}
                        update={() => setUpdate(p => !p)}
                        cartStorage={cartStorage}
                        setCartStorage={setCartStorage}
                    />)
            }
            {loading ? <Skeleton/> :
                (viewMode === "check" && ordersToCheck.length > 0)
                && ordersToCheck.map(orderTocheck =>
                    <PreOrder
                        mode={viewMode}
                        preOrder={orderTocheck}
                        accounts={accounts}
                        contragent={contragent}
                        dispatch={dispatch}
                        setBucket={setBucket}
                        update={() => setUpdate(p => !p)}
                        cartStorage={cartStorage}
                        setCartStorage={setCartStorage}
                    />,
                )
            }
            {viewMode === "created" &&
                <Typography.Title level={4} style={{textAlign: "center"}}>Заказы успешно созданы.</Typography.Title>}
        </PageHeader>
    );

}
